import { generateImageUrl } from "../helpers/imageHelper.js";
import { slugify } from "../helpers/urlHelper.js";

export const ChannelDesign = {
  FULLSCREEN: "fullscreen",
  SQUARE: "square",
  SQUARE_FULL: "square_full",
  POSTER: "poster",
  POSTER_LARGE: "poster_large",
  COVER: "cover",
  COVER_FULL: "cover_full",
  CIRCLE: "circle",
  TOTEM: "totem",
  CONTINUE_WATCHING: "continue_watching",
};

export default class Channel {
  constructor(item, baseId = undefined, type, listOptions = [], optionsValidity = [], ppvOptions = []) {
    // console.log("channel item", item);
    this.baseId = baseId;
    this.id = item.idItem || item.idCatalog || item.idChannel;
    // For PageCatchup
    //this.type = item.idType || 540;
    // For PageEPG
    this.type = type;
    if (item.tags && item.tags.imageType) {
      this.type = item.tags.imageType;
    }
    if (item.tags && item.tags.imageId) {
      this.imageId = item.tags.imageId;
    }

    if (item.tags && item.tags.length > 0) {
      let groups;
      item.tags.find((e) => {
        if (e.includes("group:")) {
          //fix stupid data structure sent from backend
          var arr = e.replace("group:", " ");
          groups = JSON.parse(arr);
        }
      });
      this.groups = groups;
    }
    this.item = item;

    if (item.name) {
      this.name = item.name;
    }
    if (item.found) {
      this.found = item.found;
    }
    if (item.synopsis) {
      this.synopsis = item.synopsis;
    }
    if (item.isFta) {
      this.fta = item.isFta;
    }
    if (item.ottConfiguration && item.ottConfiguration.timeshift) {
      this.timeshift = item.ottConfiguration.timeshift;
    }
    if (item.ottConfiguration) {
      this.startOver = false;
      if (item.ottConfiguration.startOver) {
        this.startOver = true;
      }
    }
    if (item.isSubscribed) {
      this.subscribed = item.isSubscribed;
    }
    if (item.content) {
      if (item.content.name) {
        this.name = item.content.name;
      }
      if (item.content.isFta) {
        this.fta = item.content.isFta;
      }
      if (item.content.isSubscribed) {
        this.subscribed = item.content.isSubscribed;
      }
    }

    if (item.ottConfiguration) {
      this.catchupValue = item.ottConfiguration.catchup;
      this.npvr = item.ottConfiguration.npvr;
    }

    this.localizeNumber = item.localizeNumber;
    this.epgs = [];
    // For PageEPG
    if (item.type === "OTT") {
      this.type = 100;
    }
    this.designMode = ChannelDesign.SQUARE;

    if (item.tags && item.tags.designMode && item.tags.designMode[0]) {
      this.designMode = item.tags.designMode[0];
    }
    this.moralityLevel = item.moralityLevel;
    this.ppvOptions = ppvOptions.filter((option) => option?.objectIds?.channel?.includes(this.id));
    this.listOptions = listOptions;
    this.optionsValidity = optionsValidity;
    this.idChannel = item.idChannel;
  }

  setIdChannel(idChannel) {
    this.idChannel = idChannel;
  }

  getId() {
    return this.id;
  }

  getType() {
    return this.type;
  }

  getImage(domain = "/", options) {
    return `${domain}${generateImageUrl(this, options)}`;
  }

  getPosterImage(domain = "/", options) {
    return `${domain}${generateImageUrl(this, options)}`;
  }

  getName() {
    return this.name;
  }
  getGroups() {
    return this.groups;
  }

  getSynopsis() {
    return this.synopsis;
  }

  getSlug() {
    return slugify(this.name);
  }

  addEPG(epg) {
    this.epgs.push(epg);
  }

  getEPGs() {
    return this.epgs;
  }

  getLocalizeNumber() {
    return this.localizeNumber;
  }

  setLocalizeNumber(value) {
    this.localizeNumber = value;
  }

  isSubscribed() {
    return this.subscribed;
  }

  isFta() {
    return this.fta;
  }

  setSubscribed(value) {
    this.subscribed = value;
  }

  setFta(value) {
    this.fta = value;
  }

  get userHasPpv() {
    return this.ppvOptions.length > 0;
  }

  get userHasPpvOption() {
    let hasActivePpv = false;
    const currentTime = new Date().getTime() / 1000;
    for (const option of this.ppvOptions) {
      if (
        option.objectIds &&
        option.objectIds.channel &&
        option.objectIds.channel.includes(this.id) &&
        option?.rightSlot?.end > currentTime &&
        option?.rightSlot?.start <= currentTime
      ) {
        hasActivePpv = true;
      }
    }
    return hasActivePpv;
  }

  get userHasActiveOption() {
    let optionChannels = [];
    let validStatus = {
      active: 10,
      terminated: 40,
    };

    for (const option of this.listOptions) {
      let validity = this.optionsValidity?.find((validity) => {
        return option.idOption === validity.optionId;
      });

      if (
        [validStatus.active, validStatus.terminated].includes(validity?.statusId) &&
        option.validFrom !== null &&
        (option.validTo * 1000 > new Date().getTime() || option.validTo === null)
      ) {
        if (option && option.objectIds && option.objectIds.channel) {
          optionChannels = optionChannels.concat(option.objectIds.channel);
        }
      }
    }

    return optionChannels.includes(this.idChannel || this.id);
  }

  get userHasAccess() {
    return this.isFta() || this.userHasPpvOption || this.userHasActiveOption;
  }

  getCatchupValue() {
    return this.catchupValue * 1000; // formatted second in ms.
  }

  get isCatchupChannel() {
    if (this.catchupValue > 0) {
      return true;
    } else return false;
  }

  getDesignMode() {
    return this.designMode;
  }
}
